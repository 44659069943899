import React, { useState, useEffect } from "react";
import Header from "../Header/index";
import SideBar from "../SideBar/index";
import { connect } from "react-redux";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import { getLocalStorageItem } from "../../store/localStorage";
import { formatDate } from "../../utils/commonFunction";
import { usePermissions } from "../../utils/hooks/PermisionsHook";

const UserManagement = () => {
  // Initialize state with hooks
  const retrievedObject = getLocalStorageItem("myStylistAdmin");
  const retrievedObjectPermission = getLocalStorageItem("myStylistAdminPermission");

  const [state, setState] = useState({
    id: retrievedObject?.id || "",
    token: retrievedObject?.token || "",
    results: [],
    emailErrorMsg: "",
    currentPage: 1,
    itemsPerPage: 10,
    totalItems: 0,
    sort: "desc",
    loading: true,
    search: "",
    role: "",
    successMsg: "",
    errorMsg: "",
    permissions: retrievedObjectPermission,
    showDeletePopup: false,
    selectedUserId: null,
    selectedUserName: '',
  });

  // Helper function to update state
  const updateState = (newState) => {
    setState(prev => ({ ...prev, ...newState }));
  };

  useEffect(() => {
    getOnboardingResults();
  }, []); // ComponentDidMount equivalent

  const fetchPermissions = async (adminId) => {
    updateState({ loading: true });

    const { token } = state;
    const { AdminViewAdminPermission } = adminendPoints;
    const method = "POST";
    const contentType = "application/json";

    try {
      const body = { token, id: adminId };
      const response = await APICaller(
        AdminViewAdminPermission,
        method,
        body,
        contentType,
        token
      );

      if (response?.data?.status === 200) {
        updateState({
          permissions: response.data.data.permissions,
          loading: false,
        });
      } else {
        console.error("Failed to load permissions. API Response:", response);
        updateState({
          errorMsg: "Failed to load permissions.",
          loading: false,
        });
      }
    } catch (error) {
      console.error("Error Fetching Permissions:", error);
      updateState({
        errorMsg: "Error fetching permissions.",
        loading: false,
      });
    }
  };

  const deleteUser = async (userId) => {
    const { AdminDeleteUser } = adminendPoints;
    const method = "POST";
    const body = { userId };
    const contentType = "application/json";
    await APICaller(AdminDeleteUser, method, body, contentType, state.token);
  };

  const getOnboardingResults = async () => {
    const { search, token, currentPage, itemsPerPage, sort } = state;
    const { UserListforadmin } = adminendPoints;

    const queryParams = new URLSearchParams({
      page: currentPage,
      limit: itemsPerPage,
      ...(search && { search: search.trim() }),
      ...(sort && { sort }),
    });

    const url = `${UserListforadmin}?${queryParams}`;
    updateState({ loading: true });

    try {
      const response = await APICaller(url, "GET", "", "application/json", token);
      if (response.data.status === 200) {
        const { users, totalItems } = response.data;
        updateState({ results: users, totalItems, loading: false });
      } else {
        updateState({
          emailErrorMsg: "Invalid Request",
          loading: false,
        });
        setTimeout(() => updateState({ emailErrorMsg: "" }), 10000);
      }
    } catch (error) {
      console.error("Error fetching results:", error);
      updateState({ loading: false });
    }
  };

  const handleSearchChange = (e) => {
    let value = e.target.value.replace(/\s+/g, " ");
    updateState({ search: value });

    if (value.length >= 3 || value.length === 0) {
      const timeoutId = setTimeout(() => {
        updateState({ currentPage: 1 }, getOnboardingResults);
      }, 300);
      return () => clearTimeout(timeoutId);
    }
  };

  const handlePageChange = (pageNumber) => {
    updateState({ currentPage: pageNumber }, () => {
      getOnboardingResults();
    });
  };

  const handleSort = (sortField) => {
    updateState({ sort: sortField, currentPage: 1 }, () => {
      getOnboardingResults();
    });
  };

  const handleToggleCheckBox = async (userId, currentRole) => {
    const { token } = state;
    const { OnboardingStatusUpdate } = adminendPoints;
    const method = "POST";
    const contentType = "application/json";

    const newRole = currentRole === "onboarding" ? "expert" : "onboarding";

    const body = {
      userId,
      role: newRole,
    };

    try {
      const response = await APICaller(
        OnboardingStatusUpdate,
        method,
        body,
        contentType,
        token
      );

      const { status, message } = response.data;

      if (status === 200) {
        updateState((prevState) => ({
          results: prevState.results.map((onBoardingUser) =>
            onBoardingUser._id === userId
              ? { ...onBoardingUser, role: newRole }
              : onBoardingUser
          ),
          successMsg: `User's role has been successfully updated to ${newRole}`,
          errorMsg: "",
        }));

        setTimeout(() => {
          updateState({ successMsg: "" });
          getOnboardingResults();
        }, 2000);
      } else {
        console.error("Failed to update offer status:", message);
        updateState({
          errorMsg: message,
          successMsg: "",
        });
      }
    } catch (error) {
      console.error("Error making data call:", error);
      updateState({
        errorMsg: "An error occurred while updating the user's role.",
        successMsg: "",
      });
    }
  };

  const toggleDeletePopup = (userId = null, userName = '') => {
    updateState({
      showDeletePopup: !state.showDeletePopup,
      selectedUserId: userId,
      selectedUserName: userName,
    });
  };

  const handleDeleteConfirm = async () => {
    try {
      await deleteUser(state.selectedUserId);
      updateState({
        successMsg: "User deleted successfully",
        showDeletePopup: false,
      });
      getOnboardingResults();
    } catch (error) {
      updateState({
        errorMsg: "Failed to delete user",
        showDeletePopup: false,
      });
    }
  };

  const {
    results,
    totalItems,
    currentPage,
    itemsPerPage,
    loading,
    emailErrorMsg,
    search,
    sort,
    successMsg,
    errorMsg,
    permissions,
    showDeletePopup,
    selectedUserId,
    selectedUserName,
  } = state;

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const { hasPermission } = usePermissions();

  return (
    <div className="main d-flex">
      <SideBar />
      <div className="main-right-content flex-fill">
        <Header />
        <div className="main-right-content-inner">
          <div className="card mb-4">
            <div className="card-body pb-0">
              <div className="d-flex align-items-center mb-3">
                <div>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="#">Home</a>
                    </li>
                    <li className="breadcrumb-item active">
                      Manage Users
                    </li>
                  </ol>
                  <h4 className="card-title mb-0">Manage Users</h4>
                </div>
              </div>
            </div>
          </div>
          {loading && (
            <div className="text-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}

          {successMsg && (
            <div className="alert alert-success" role="alert">
              {successMsg}
            </div>
          )}

          {errorMsg && (
            <div className="alert alert-danger" role="alert">
              {errorMsg}
            </div>
          )}

          <div className="card mb-4">
            <div className="card-body">
              <div className="row align-items-center mb-3">
                <div className="col-lg-5">
                  <div className="form-control-group-icon search">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search here..."
                      value={search}
                      onChange={handleSearchChange}
                    />
                    <i className="fa-solid fa-magnifying-glass"></i>
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="d-flex align-items-center justify-content-end">
                    <div className="form-control-group-icon ms-3">
                      <select
                        className="form-select w-auto"
                        value={sort}
                        onChange={(e) => handleSort(e.target.value)} // Passing the string value directly
                      >
                        <option value="asc">Ascending</option>
                        <option value="desc">Descending</option>
                      </select>
                      <i className="fa-solid fa-magnifying-glass"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body p-0">
              <table className="table align-middle mb-4">
                <thead>
                  <tr>
                    <th>SN.</th>
                    <th>Name</th>
                    <th>Phone</th>
                    <th>Gender</th>
                    <th>City Name</th>
                    <th>Status</th>
                    <th>Created Date</th>
                    <th className="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {results.length === 0 ? (
                    <tr>
                      <td colSpan="7" className="text-center">
                        No users available for the selected categories.
                      </td>
                    </tr>
                  ) : (
                    results.map((item, index) => {
                      const serialNumber = Math.min(
                        totalItems,
                        (currentPage - 1) * itemsPerPage + (index + 1)
                      );

                      return (
                        <tr key={index}>
                          <td>{serialNumber}.</td>
                          <td>{item.name}</td>
                          <td>{item.phone}</td>
                          <td>{item.gender}</td>
                          <td>
                            {item.city[0]?.city_name}
                            {item.city[0]?.city_name &&
                              ` (${item.city[0].city_name[0].toUpperCase()})`}
                          </td>


                          <td className="text-center">
                            {item.role && (
                              <div className="form-check form-switch d-inline-block p-0 m-0">
                                <input
                                  disabled={!hasPermission("adminOnboardingUsers", "edit")}
                                  className="form-check-input m-0"
                                  type="checkbox"
                                  role="switch"
                                  checked={item.role === "expert"} // You may change this condition based on the role
                                  onChange={() =>
                                    handleToggleCheckBox(
                                      item._id,
                                      item.role
                                    )
                                  } // Pass current role
                                />
                              </div>
                            )}
                          </td>
                          <td>{formatDate(item.createdAt)}</td>
                      
                            <td className="text-center">
                              <a
                                href={hasPermission("adminOnboardingUsers", "edit") ? `/EditExpert/${item._id}` : "#"}
                                className="icon-btn me-2"
                              >
                                <img
                                  src="assets/img/icon-edit.svg"
                                  alt=""
                                  title=""
                                />
                              </a>
                          
                                <button
                                  disabled={!hasPermission("adminOnboardingUsers", "delete")}
                                  className="icon-btn text-danger border-0 bg-transparent"
                                  onClick={() => toggleDeletePopup(item._id, item.name)}
                                  title="Delete"
                                >
                                  <i className="fa-solid fa-trash-can"></i>
                                </button>
                              
                            </td>
                         

                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
              <div className="d-flex align-items-center justify-content-end p-3">
                {currentPage > 1 && (
                  <button
                    className="pagination-prev me-2"
                    onClick={(e) => handlePageChange(currentPage - 1, e)}
                  >
                    <i className="fa-solid fa-angle-left"></i>
                  </button>
                )}

                <ul className="pagination m-0">
                  {Array.from({ length: totalPages }).map((_, index) => (
                    <li
                      key={index}
                      className={`page-item ${currentPage === index + 1 ? "active" : ""
                        }`}
                    >
                      <button
                        className="page-link"
                        onClick={() => handlePageChange(index + 1)}
                      >
                        {index + 1}
                      </button>
                    </li>
                  ))}
                </ul>

                {currentPage < totalPages && (
                  <button
                    className="pagination-next ms-2"
                    onClick={(e) => handlePageChange(currentPage + 1, e)}
                  >
                    <i className="fa-solid fa-angle-right"></i>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {showDeletePopup && (
        <>
          <div className="modal-backdrop show" style={{ opacity: 0.5 }}></div>
          <div className="modal show d-block" tabIndex="-1">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header border-bottom-0">
                  <h5 className="modal-title">Confirm Delete</h5>
                  <button
                    type="button"
                    className="close"
                    onClick={() => toggleDeletePopup()}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body text-center py-4">
                  <div className="mb-4">
                    <i className="fa-solid fa-triangle-exclamation text-warning" style={{ fontSize: '3rem' }}></i>
                  </div>
                  <h5>Are you sure you want to delete this user?</h5>
                  <p className="text-muted mb-0">
                    {selectedUserName}
                  </p>
                </div>
                <div className="modal-footer border-top-0">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => toggleDeletePopup()}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={handleDeleteConfirm}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  permissions: state.permissions,
});

export default connect(mapStateToProps)(UserManagement);
