import React, { Component } from "react";
import Header from "../Header/index";
import SideBar from "../SideBar/index";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import { getLocalStorageItem } from "../../store/localStorage";
import { formatDate, fetchPermissions } from "../../utils/commonFunction";

class ExpertList extends Component {
  constructor(props) {
    super(props);

    var retrievedObject = getLocalStorageItem("myStylistAdmin");
    var retrievedObjectPermission = getLocalStorageItem(
      "myStylistAdminPermission"
    );

    this.state = {
      id: retrievedObject ? retrievedObject.id : "",
      token: retrievedObject ? retrievedObject.token : "",
      results: [],
      emailErrorMsg: "",
      successMsg: "",
      errorMsg: "",
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: 0,
      search: "",
      sort: "desc",
      activeTab: "All",
      loading: true,
      showPopup: false,
      expertPhone: "",
      errors: {},
      successMsgModel: "",
      errorMsgModel: "",
      permissions: retrievedObjectPermission,
      showDeletePopup: false,
      selectedExpertId: null,
      selectedExpertName: '',
    };
  }

  componentDidMount() {
    // const { id, token } = this.state;
    // if (id) {
    //   const fetchPermissionsData = async () => {
    //     try {
    //       const permissions = await fetchPermissions(token, id);
    //       this.setState({ permissions });
    //     } catch (error) {
    //       console.error("Error fetching permissions:", error);
    //     }
    //   };
    //   fetchPermissionsData();
    // }
    this.getExpertResults();
  }

  deleteExpert = async (expertId) => {
    const { AdminDeleteExpert } = adminendPoints;
    const method = "POST";
    const body = { expertId };
    const contentType = "application/json";
    const token = this.state.token;
    await APICaller(AdminDeleteExpert, method, body, contentType, token);
  };

  getExpertResults = () => {
    const { search, sort, token, currentPage, itemsPerPage, activeTab } =
      this.state;
    const { AdminExpertList } = adminendPoints;
    const method = "GET";
    const contentType = "application/json";

    // Construct query parameters
    const queryParams = new URLSearchParams({
      page: currentPage,
      limit: itemsPerPage,
      ...(search && { search: search.trim() }),
      ...(sort && { sort }),
      ...(activeTab && { status: activeTab }), // Adding status conditionally
    });

    const url = `${AdminExpertList}${queryParams ? `?${queryParams}` : ""}`;

    this.setState({ loading: true });

    APICaller(url, method, "", contentType, token)
      .then((response) => {
        const { status, expertusers, totalItems } = response.data;

        if (status === 200) {
          this.setState({
            results: expertusers,
            totalItems: totalItems,
            loading: false,
          });
        } else {
          this.setState(
            {
              emailErrorMsg: "Invalid Request",
              loading: false,
            },
            () => {
              window.setTimeout(() => {
                this.setState({
                  emailErrorMsg: "",
                });
              }, 10000);
            }
          );
        }
      })
      .catch((error) => {
        console.log("error making data call --> ", error);
        this.setState({
          loading: false,
        });
      });
  };

  handleToggleCheckBox = async (expertId, currentStatus) => {
    const { token } = this.state;
    const { AdminUpdateStatusExpert } = adminendPoints;
    const method = "POST";
    const contentType = "application/json";

    const newStatus = currentStatus === "Active" ? "In-Active" : "Active";

    const body = {
      expertId,
      status: newStatus,
    };
    this.setState({ loading: true });

    try {
      const response = await APICaller(
        AdminUpdateStatusExpert,
        method,
        body,
        contentType,
        token
      );

      const { status, message } = response.data;
      if (status === 200) {
        this.setState((prevState) => ({
          results: prevState.results.map((expert) =>
            expert._id === expertId ? { ...expert, status: newStatus } : expert
          ),
          successMsg: `Expert Status is ${newStatus}`,
          errorMsg: "",
          loading: false, // Hide loader after success
        }));
        setTimeout(() => {
          this.setState({ successMsg: "" });
        }, 2000);
        this.getExpertResults();
      } else {
        console.error("Failed to update faq status");
        this.setState({
          errorMsg: message,
          successMsg: "",
          loading: false, // Hide loader after success
        });
      }
    } catch (error) {
      console.error("Error making data call:", error);
      this.setState({
        errorMsg: "An error occurred while updating offer status.",
        successMsg: "",
        loading: false, // Hide loader after success
      });
    }
  };

  handlePageChange = (pageNumber) => {
    this.setState({ currentPage: pageNumber }, () => {
      this.getExpertResults();
    });
  };

  handleTabClick = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab, currentPage: 1, loading: true }, () => {
        this.getExpertResults();
      });
    }
  };

  handleSearchChange = (e) => {
    let value = e.target.value.replace(/\s+/g, " ");

    this.setState({ search: value });

    if (this.searchDebounce) clearTimeout(this.searchDebounce);

    if (value.length >= 3 || value.length === 0) {
      this.searchDebounce = setTimeout(() => {
        this.setState({ currentPage: 1 }, this.getExpertResults);
      }, 300);
    }
  };

  validateForm = () => {
    const errors = {};
    const { expertPhone } = this.state;

    if (!expertPhone) {
      errors.expertPhone = "Phone number is required.";
    } else if (!/^\d{10}$/.test(expertPhone)) {
      errors.expertPhone = "Phone number must be 10 digits.";
    }

    return errors;
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = this.validateForm();

    if (Object.keys(newErrors).length > 0) {
      this.setState({ errors: newErrors });
      setTimeout(() => {
        this.setState({ errors: {} });
      }, 5000);
    } else {
      this.setState({ errors: {} });

      const { expertPhone } = this.state;

      const { AdminExpertRegister } = adminendPoints;
      const method = "POST";
      const body = { phone: expertPhone };
      const contentType = "application/json";

      try {
        const response = await APICaller(
          AdminExpertRegister,
          method,
          body,
          contentType,
          ""
        );

        const { status } = response.data;

        if (status === 200) {
          this.setState(
            {
              successMsgModel: "Stylist created successfully.",
              errorMsgModel: "",
            },
            () => {
              setTimeout(() => {
                this.setState({ successMsgModel: "", redirect: true });
                this.togglePopup(); // Hide the modal
              }, 5000);
            }
          );
        } else if (status === 409) {
          this.setState(
            {
              errorMsgModel: "This phone number is already registered.",
              successMsgModel: "",
            },
            () => {
              setTimeout(() => {
                this.setState({ errorMsgModel: "", redirect: false });
              }, 3000);
            }
          );
        } else {
          this.setState(
            {
              errorMsgModel: "Failed to create the expert. Please try again.",
              successMsgModel: "",
            },
            () => {
              setTimeout(() => {
                this.setState({ errorMsgModel: "", redirect: false });
              }, 3000);
            }
          );
        }
      } catch (error) {
        console.log("Error making data call --> ", error);
        this.setState({
          errorMsgModel:
            "An error occurred while creating the expert. Please try again.",
          successMsgModel: "",
        });
      }
    }
  };

  togglePopup = () => {
    this.setState((prevState) => ({
      showPopup: !prevState.showPopup,
      expertPhone: "",
      errors: {},
    }));
  };

  handleChange = (e) => {
    const { id, value } = e.target;
    this.setState({ [id]: value });
  };

  handleSort = (sortField) => {
    this.setState({ sort: sortField, currentPage: 1 }, () => {
      this.getExpertResults();
    });
  };

  toggleDeletePopup = (expertId = null, expertName = '') => {
    this.setState({
      showDeletePopup: !this.state.showDeletePopup,
      selectedExpertId: expertId,
      selectedExpertName: expertName,
    });
  };

  handleDeleteConfirm = async () => {
    try {
      await this.deleteExpert(this.state.selectedExpertId);
      this.setState({
        successMsg: "Stylist deleted successfully",
        showDeletePopup: false,
      });
      this.getExpertResults();
    } catch (error) {
      this.setState({
        errorMsg: "Failed to delete stylist",
        showDeletePopup: false,
      });
    }
  };

  render() {
    const {
      results,
      token,
      search,
      currentPage,
      itemsPerPage,
      activeTab,
      sort,
      isVisibleView,
      isVisibleAdd,
      totalItems,
      successMsg,
      errorMsg,
      loading,
      showPopup,
      errors,
      expertPhone,
      successMsgModel,
      errorMsgModel,
      permissions,
      showDeletePopup,
      selectedExpertId,
      selectedExpertName,
    } = this.state;

    if (token === "") {
      return <Navigate to="/" />;
    }

    const totalPages = Math.ceil(totalItems / itemsPerPage);

    return (
      <div className="main d-flex">
        <SideBar />
        <div className="main-right-content flex-fill">
          <Header />
          <div className="main-right-content-inner">
            <div className="card mb-4">
              <div className="card-body pb-0">
                <div className="d-flex align-items-center mb-3">
                  <div>
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a href="#">Home</a>
                      </li>
                      <li className="breadcrumb-item active">
                        Manage Stylist's
                      </li>
                    </ol>
                    <h4 className="card-title mb-0">Manage Stylist's</h4>
                  </div>
                  <div className="ms-auto">
                    <button
                      type="button"
                      className="btn-blue ms-2"
                      onClick={this.togglePopup}
                    >
                      <i className="fa-solid fa-plus me-2"></i>Add New
                    </button>
                    <button type="button" className="btn-light1 ms-2">
                      <i className="fa-solid fa-file-export me-2"></i>View
                      Stylist
                    </button>
                  </div>
                </div>
                <div className="tabs1 d-flex">
                  <Link
                    onClick={() => this.handleTabClick("All")}
                    className={activeTab === "All" ? "active" : ""}
                  >
                    All
                  </Link>
                  <Link
                    onClick={() => this.handleTabClick("Active")}
                    className={activeTab === "Active" ? "active" : ""}
                  >
                    Active
                  </Link>
                  <Link
                    onClick={() => this.handleTabClick("In-Active")}
                    className={activeTab === "In-Active" ? "active" : ""}
                  >
                    In-Active
                  </Link>
                </div>
              </div>
            </div>

            {loading && (
              <div className="text-center">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            )}

            {successMsg && (
              <div className="alert alert-success" role="alert">
                {successMsg}
              </div>
            )}

            {errorMsg && (
              <div className="alert alert-danger" role="alert">
                {errorMsg}
              </div>
            )}

            <div className="card mb-4">
              <div className="card-body">
                <div className="row align-items-center mb-3">
                  <div className="col-lg-5">
                    <div className="form-control-group-icon search">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search here..."
                        value={search}
                        onChange={this.handleSearchChange}
                      />
                      <i className="fa-solid fa-magnifying-glass"></i>
                    </div>
                  </div>
                  <div className="col-lg-7">
                    <div className="d-flex align-items-center justify-content-end">
                      <div className="form-control-group-icon ms-3">
                        <select
                          className="form-select w-auto"
                          value={sort}
                          onChange={(e) => this.handleSort(e.target.value)}
                        >
                          <option value="asc">Ascending</option>
                          <option value="desc">Descending</option>
                        </select>

                        <i className="fa-solid fa-magnifying-glass"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card-body p-0">
                <table className="table align-middle mb-4">
                  <thead>
                    <tr>
                      <th>SN.</th>
                      <th>Name</th>
                      <th>Phone</th>
                      <th>Gender</th>
                      <th>Joining Date</th>
                      <th>Profile Status (%) </th>
                      <th className="text-center">Status</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {results.length === 0 ? (
                      <tr>
                        <td colSpan="7" className="text-center">
                          No users available for the selected categories.
                        </td>
                      </tr>
                    ) : (
                      results.map((category, index) => {
                        const serialNumber =
                          (currentPage - 1) * itemsPerPage + index + 1;
                        return (
                          <tr key={index}>
                            <td>{serialNumber}.</td>
                            <td>{category.name}</td>
                            <td>{category.phone}</td>
                            <td>{category.gender}</td>
                            <td>{formatDate(category.createdAt)}</td>
                            <td>
                              <div className="progress" style={{ width: "100px", height: "20px", borderRadius: "10px", backgroundColor: "#f0f0f0" }}>

                                <div
                                  className="progress-bar"
                                  role="progressbar"
                                  style={{
                                    width: `${category?.dataFilledOf}%`,
                                    background: "linear-gradient(to right, #89e3dc, #8BC34A)",
                                    borderRadius: "10px",
                                    transition: "width 1s ease-in-out",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    color: "#fff",
                                    boxShadow: "0 2px 5px rgba(0,0,0,0.1)"
                                  }}
                                  aria-valuenow={category?.dataFilledOf}
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                >
                                  {category?.dataFilledOf}%
                                </div>
                              </div>
                            </td>

                            {permissions?.stylistManagement?.status && (
                              <td className="text-center">
                                {category.status && (
                                  <div className="form-check form-switch d-inline-block p-0 m-0">
                                    <input
                                      className="form-check-input m-0"
                                      type="checkbox"
                                      role="switch"
                                      checked={category.status === "Active"}
                                      disabled={category.dataFilledOf < 100 && category.status === "In-Active"}
                                      onChange={() =>
                                        this.handleToggleCheckBox(
                                          category._id,
                                          category.status
                                        )
                                      }
                                    />
                                  </div>
                                )}
                              </td>
                            )}

                            {permissions?.stylistManagement?.edit && (
                              <td className="text-center">
                                <Link
                                  to={`/EditExpert/${category._id}`}
                                  title="Edit"
                                  className="icon-btn me-2"
                                >
                                  <img
                                    src="assets/img/icon-edit.svg"
                                    alt=""
                                    title=""
                                  />
                                </Link>
                                {permissions?.stylistManagement?.delete && (
                                  <button
                                    className="icon-btn text-danger border-0 bg-transparent"
                                    onClick={() => this.toggleDeletePopup(category._id, category.name)}
                                    title="Delete"
                                  >
                                    <i className="fa-solid fa-trash-can"></i>
                                  </button>
                                )}
                              </td>
                            )}
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>

                <div className="d-flex align-items-center justify-content-end p-3">
                  {currentPage > 1 && (
                    <button
                      className="pagination-prev me-2"
                      onClick={(e) => this.handlePageChange(currentPage - 1, e)}
                    >
                      <i className="fa-solid fa-angle-left"></i>
                    </button>
                  )}
                  <ul className="pagination m-0">
                    {Array.from({ length: totalPages }).map((_, index) => (
                      <li
                        key={index}
                        className={`page-item ${currentPage === index + 1 ? "active" : ""
                          }`}
                      >
                        <button
                          className="page-link"
                          onClick={(e) => this.handlePageChange(index + 1, e)}
                        >
                          {index + 1}
                        </button>
                      </li>
                    ))}
                  </ul>
                  {currentPage < totalPages && (
                    <button
                      className="pagination-next"
                      onClick={(e) => this.handlePageChange(currentPage + 1, e)}
                    >
                      <i className="fa-solid fa-angle-right"></i>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {showPopup && (
          <div className="modal show d-block" tabIndex="-1" role="dialog">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Add New Expert</h5>
                  <button
                    type="button"
                    className="close"
                    onClick={this.togglePopup}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  {successMsgModel && (
                    <div className="alert alert-success" role="alert">
                      {successMsgModel}
                    </div>
                  )}
                  {errorMsgModel && (
                    <div className="alert alert-danger" role="alert">
                      {errorMsgModel}
                    </div>
                  )}
                  <form>
                    <div className="form-group">
                      <label htmlFor="expertPhone">Phone</label>
                      <input
                        type="text"
                        className={`form-control ${errors.expertPhone ? "is-invalid" : ""
                          }`}
                        id="expertPhone"
                        value={expertPhone}
                        onChange={this.handleChange}
                      />
                      {errors.expertPhone && (
                        <div className="invalid-feedback">
                          {errors.expertPhone}
                        </div>
                      )}
                    </div>
                  </form>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={this.handleSubmit}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={this.togglePopup}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {showDeletePopup && (
          <>
            <div className="modal-backdrop show" style={{ opacity: 0.5 }}></div>
            <div className="modal show d-block" tabIndex="-1">
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header border-bottom-0">
                    <h5 className="modal-title">Confirm Delete</h5>
                    <button
                      type="button"
                      className="close"
                      onClick={() => this.toggleDeletePopup()}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body text-center py-4">
                    <div className="mb-4">
                      <i className="fa-solid fa-triangle-exclamation text-warning" style={{ fontSize: '3rem' }}></i>
                    </div>
                    <h5>Are you sure you want to delete this stylist?</h5>
                    <p className="text-muted mb-0">
                      {selectedExpertName}
                    </p>
                  </div>
                  <div className="modal-footer border-top-0">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => this.toggleDeletePopup()}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={this.handleDeleteConfirm}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default ExpertList;
